<template>
    <v-container fluid>
        <v-dialog
            persistent
            max-width="960px"
            transition="dialog-bottom-transition"
            v-model="dialogForm"
        >
            <v-form @submit.prevent="saveDocumentFlowStatus">
                <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                    <v-card flat>
                        <v-card-text class="py-0">
                            <v-row>
                                <v-col cols="12">
                                    <v-row class="d-flex mt-5">
                                        <v-col cols="10" class="ma-auto">
                                            <span class="title_menu">{{ heading }}</span>
                                        </v-col>
                                        <v-col cols="2" class="d-flex justify-end">
                                            <v-icon @click="closeDialogAdd" :title="$t('close')" color="primary" >mdi-close</v-icon>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="mt-2">
                                <v-col class="py-0" cols="12">
                                    <v-tabs v-model="tab" background-color="transparent"
                                            :right="$vuetify.breakpoint.mdAndUp"
                                            :grow="$vuetify.breakpoint.smAndDown"
                                            color="deep-purple accent-4" @change="setLanguage" class="pb-4">
                                        <v-tab ref="language" v-for="language in languages" :key="language">
                                            <span :class="exist_translations[language] ? '' : 'red--text '">
                                                {{ language }}
                                            </span>
                                        </v-tab>
                                    </v-tabs>
                                </v-col>
                            </v-row>
                            <v-row class="mt-5">
                                <v-col cols="12" sm="12">
                                    <ValidationProvider ref="document_flow_status" rules="required|min:1|max:255"
                                                        v-slot="{ errors, valid }">
                                        <TextInput
                                            hide-label
                                            :error="valid === false"
                                            :error-messages="errors"
                                            id="document_flow_status"
                                            v-model="document_flow_status"
                                            :label="$t('document_flow_status_name')"
                                        ></TextInput>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-color-picker v-model="color" mode="hexa" :disabled="loading" width="920"></v-color-picker>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="py-0 my-0 d-flex justify-start" cols="12" sm="4" >
                                    <v-switch v-model="begin"
                                              :disabled="loading"
                                              :label="$t('begin_status')"
                                              class="input_switch py-0"
                                              color="primary" hide-details></v-switch>
                                </v-col>
                                <v-col class="py-0 my-0 d-flex justify-start justify-sm-end" cols="12" sm="4">
                                    <v-switch v-model="partly"
                                              :disabled="loading"
                                              :label="$t('partly_status')"
                                              class="input_switch py-0"
                                              color="primary" hide-details></v-switch>
                                </v-col>
                                <v-col class="py-0 my-0 d-flex justify-start justify-sm-end" cols="12" sm="4">
                                    <v-switch v-model="completed"
                                              :disabled="loading"
                                              :label="$t('completed_status')"
                                              class="input_switch py-0"
                                              color="primary" hide-details></v-switch>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions class="px-4 pt-10 pb-7">
                            <Btn large type="submit" color="primary" :disabled="invalid || loading" >
                                {{ $t('save') }}
                            </Btn>
                            <Btn plain @click="closeDialogAdd" color="primary">
                                {{ $t('cancel') }}
                            </Btn>
                            <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                            <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                               :active="loading" class="mx-2"></v-progress-linear>

                        </v-card-actions>

                    </v-card>
                </ValidationObserver>
            </v-form>
        </v-dialog>
    </v-container>
</template>
<script>


    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {mapGetters} from "vuex";
    import TextInput from "@/components/Form/TextInput";
    import Btn from "@/components/Form/Btn.vue";

    export default {
        name: 'DocumentFlowStatusForm',
        components: {
            ValidationProvider,
            ValidationObserver,
            TextInput,
            Btn
        },
        inject: ['forceRerender'],
        data() {
            return {
                dialogForm: true,
                heading: null,
                progress: 0,
                loading: false,
                tab: 0,
                language: null,
                id: null,
                document_flow_status: null,
                exist_translations: {},
                all_translations: true,
                color: '#ff0000',
                begin: false,
                partly: false,
                completed: false,

            }
        },
        computed: {
            ...mapGetters(['listLanguages', 'lang']),
            languages() {
                return this.listLanguages
            },
        },
        mounted() {
            this.language = this.languages[this.tab]
            this.checkCreate()
        },
        methods: {
            checkCreate() {
                if (this.$route.name === "document_flow_status.create") {
                    this.heading = this.$t('document_flow_status_creation')
                } else {
                    this.heading = this.$t('document_flow_status_editing')
                    if (this.$route.params.id) {
                        this.getDocumentFlowStatus()
                    }
                }
            },
            closeDialogAdd(){
                if(window.history.length > 2){
                    this.$router.back()
                }
                else{
                    this.$router.push({
                        name: 'document_flow_status',
                    })
                }
            },
            setLanguage(val) {
                this.language = this.languages[val]
                this.checkCreate()
            },
            async getDocumentFlowStatus() {
                var _this = this;
                this.progress = 0
                this.loading = true
                let params = {}
                if (this.language) {
                    params.language = this.language
                }
                await this.$http
                    .get(`admin/document_flow_status/${this.$route.params.id}`, {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.document_flow_status = res.body.data.name
                        let color = res.body.data.color
                        this.color = color.match(/^#[0-9a-zA-Z]{6}$/) ? color : '#FF0000'
                        this.begin = res.body.data.begin
                        this.partly = res.body.data.partly
                        this.completed = res.body.data.completed
                        this.exist_translations = res.body.data.exist_translations
                        this.all_translations = res.body.data.all_translations
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('failed_to_get_document_flow_status'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async saveDocumentFlowStatus() {
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()
                if (this.languages && this.languages[this.tab]) {
                    formData.append('language', this.languages[this.tab])
                }
                if (this.document_flow_status) {
                    formData.append('document_flow_status_name', this.document_flow_status)
                }
                if (this.color) {
                    formData.append('color', this.color)
                }
                if (this.begin) {
                    formData.append('begin', 1)
                }
                if (this.partly) {
                    formData.append('partly', 1)
                }
                if (this.completed) {
                    formData.append('completed', 1)
                }

                if (this.$route.params.id) {
                    await this.$http
                        .put(`admin/document_flow_status/${this.$route.params.id}`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('document_flow_status_has_been_updated'))
                            if (res && res.body && res.body.data && res.body.data.exist_translations) {
                                this.exist_translations = res.body.data.exist_translations
                                this.all_translations = res.body.data.all_translations
                            }
                            this.closeDialogAdd()
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('document_flow_status_has_not_been_updated'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                } else {
                    // Add
                    await this.$http
                        .post('admin/document_flow_status', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('document_flow_status_has_been_added'))
                            if (res && res.body && res.body.data && res.body.data.id) {
                                this.exist_translations = res.body.data.exist_translations
                                this.all_translations = res.body.data.all_translations
                                this.$router.push({
                                    name: 'document_flow_status.edit',
                                    params: {
                                        id: res.body.data.id
                                    }
                                })
                            } else {
                                this.$router.push({
                                    name: 'document_flow_status'
                                })
                            }
                            this.forceRerender()
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('document_flow_status_has_not_been_added'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                }
            },

        }
    }
</script>
